/**
* Generated automatically at built-time (2025-01-24T17:19:39.487Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "skeldervik-utekjokken",templateKey: "sites/100-c65e8793-e731-4a35-b5c6-b59cd5045814"};