/**
* Generated automatically at built-time (2025-01-24T17:19:39.403Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-inox-range",templateKey: "sites/100-96c72fbe-5b73-4278-a456-42b0b519e269"};